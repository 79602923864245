<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="达人姓名" v-model="searchParam.topicTitle" />
        <!-- <v-input label="社区达人姓名" v-model="searchParam.talentUserName" /> -->
        <v-date-picker
          label="创建时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="创建开始时间"
          endPlaceholder="创建结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getTopicListURL, deleteTopicURL } from "./api.js";
import {} from "./map.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";

export default {
  name: "topicList",
  data() {
    return {
      searchParam: {
        communityId: null,
        topicTitle: "",
        topicType: 6,
        createTimeS: "",
        createTimeE: "",
      },
      tableUrl: getTopicListURL,
      headers: [
        // {
        //   prop: "talentUserName",
        //   label: "社区达人姓名",
        // },
        // {
        //   prop: "talentType",
        //   label: "社区达人类型",
        //   formatter: (row, prop) => {
        //     return this.talentTypeMap[row[prop]] || "--";
        //   },
        // },
        {
          prop: "topicPicture",
          label: "达人照片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "topicTitle",
          label: "达人姓名",
        },
        {
          prop: "remark",
          label: "描述",
        },
        {
          prop: "isTopSort",
          label: "排序",
        },
        {
          prop: "createTime",
          label: "创建时间",
        },
      ],
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.createTimeS && !this.searchParam.createTimeE
          ? []
          : [this.searchParam.createTimeS, this.searchParam.createTimeE];
      },
      set(val) {
        [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
          "",
          "",
        ];
      },
    },
  },
  mounted() {
    this.getCommunitList();
    // this.getTalentType();
  },
  methods: {
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios
          .post(`${deleteTopicURL}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toAdd() {
      this.$router.push({
        name: "createWorkTalentForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "createWorkTalentForm",
        query: { id: row.id, communityId: row.communityId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
